import React from 'react'

import {
    Avatar,
    IconButton,
    Card,
    Grid,
    Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: '#3f3f3f'
        // background: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(77,104,164,1) 0%, rgba(28,29,17,1) 100%)',
    },
    myCard: {
        fontWeight: 'bold',
        minWidth: '60vw',
        minHeight: '60vh',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        /*backgroundImage:`url(${images})`,
        backgroundSize: 'cover',*/
        background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(66,181,129,1) 100%)",
        padding: 3
    },
    myQuestion: {
        padding: 2,
        minWidth: '50vw',
        minHeight: '15vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(66,181,129,1) 100%)",

    },
    myLabels: {
        fontSize: '2rem',
        textAlign: 'center',
        fontFamily: 'Varela Round',
        color: 'black'
    },
    myText: {
        fontSize: '1.2rem',
        opacity: '0.8',
        textAlign: 'center',
        fontFamily: 'Varela Round',
        color: 'white',
        padding: 1
    },
    myButton: {
        textTransform: 'none',
        borderRadius: 20,
        minWidth: '50vw',
        minHeight: '5vh',
        margin: 2,
    }
}
export default function About() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/main/*')
    };
    return (
        <Grid container sx={classes.container}>
<IconButton sx={classes.myIcon} onClick={handleBack}>
                        <ArrowBackIcon sx={{ fontSize: 50 }} />
                    </IconButton>
            <Card sx={classes.myCard}>
                  <Typography sx={{marginLeft:2, justifyContent: 'center', alignItems:'center', display:'flex', flexDirection:'column'}}>
                      Along with the advancement of technology, 
                       mobile application creates a means to support
                        and supplement the learning of the students. </Typography>
                        <Typography sx={{marginLeft:2, justifyContent: 'center', alignItems:'center', display:'flex', flexDirection:'column'}}> The Frog mobile application will serve as a supplemental
                         material to support discussion, conceptual memorization, 
                         and improve students'
                       conceptual understanding of the anatomy of frog.</Typography>
                 <br/><br/><br/>
                   <Typography >Auditor, Czarisse Mae S.</Typography>
                   <Typography >Minitiva, Nieva O.</Typography>
                   <Typography >Luyong, Jayrill Mae D.</Typography>
                   <br/><br/>
                   <Typography sx={{fontWeight:'bold', fontSize:'18px'}}>BSED- SCIENCE 4A</Typography>
            </Card>
        </Grid>
    )
}
